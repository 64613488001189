import React from 'react';
import { Route } from 'react-router-dom';
import PageHeaderLayout from '../layouts/PageHeaderLayout/PageHeaderLayout';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';

import DashboardPage from './DashboardPage';
import MarketDataPage from './MarketDataPage';
import BuySellPage from './BuySellPage';
import WalletsPage from './WalletsPage';
import ProductDetailsPage from './ProductDetailsPage';
import ComponentExhibitPage from './ComponentExhibitPage/ComponentExhibitPage';
import SettingsPage from './SettingsPage';

import ExchangePage from '../pages/Exchange/ExchangePage';
import TradingLayout from 'apex-web/lib/layouts/TradingLayout/TradingLayoutContainer';
import MarginBalancesLayout from 'apex-web/lib/layouts/MarginBalancesLayout/MarginBalancesLayoutContainer';
import AssetActivityDetailsLayout from 'apex-web/lib/layouts/AssetActivityDetailsLayout/AssetActivityDetailsLayout';

import FixedLeftFluidLayout from 'apex-web/lib/layouts/FixedLeftFluidLayout/FixedLeftFluidLayout';
import WalletDetailsBackButtonComponent from 'apex-web/lib/components/WalletDetails/WalletDetailsBackButtonComponent';
import BalancesMenuContainer from 'apex-web/lib/components/BalancesMenu/BalanceMenuContainer';
import WalletDetailsLayout from 'apex-web/lib/layouts/WalletDetailsLayout/WalletDetailsLayout';

import DigitalAssetsPage from 'apex-web/lib/components/DigitalAssets/DigitalAssetsPageContainer';
import DigitalAssetsOfferingProfilePage from 'apex-web/lib/components/DigitalAssets/DigitalAssetsOfferingProfilePageContainer';
import JoomioPage from './JoomioPage';
import MercuryoPage from './MercuryoPage';
import SuccessPage from './SuccessPage';
import CardResultPage from './CardResultPage';
import ErrorPage from './ErrorPage';
import IBALayout from 'apex-web/lib/components/IBA/IbaLayout';
import CashOutPage from './CashOutPage';
import PrepaidCardsPage from './PrepaidCardsPage';
import Navbar from '../components/NewNav';
// import NewNav from '../components/NewNav/NewNav';

import PurchaseFormPage from './PurchaseFormPage';
import PurchaseFormPage_AMA from './PurchaseFormPage_AMA';
import PurchaseFormPage_WNW_special from './PurchaseFormPage_WNW_special';
import PurchaseFormPage_GreenX_Preseed from './PurchaseFormPage_GreenX_Preseed';
import PurchaseFormPage_GX_Presale from './PurchaseFormPage_GX_Presale';
// import CMSaleChannel from './CM_sale_channel';

const InteriorPage = () => (
  <React.Fragment>
    <Navbar />
    {/* <NewNav />
    <PageHeaderLayout />*/}
    <Route exact path={'/dashboard'} component={DashboardPage} />
    <Route exact path={'/dashboard/zh'} component={DashboardPage} />
    <Route exact path={'/dashboard/en'} component={DashboardPage} />

    <Route path={'/dashboard/market-data'} component={MarketDataPage} />
    <Route exact path={'/iba'} component={IBALayout} />
    <Route exact path={'/wallets'} component={WalletsPage} />
    <Route
      path="/wallets/wallet-detail"
      component={() => (
        <FixedLeftFluidLayout
          left={[
            <WalletDetailsBackButtonComponent key="1" />,
            <BalancesMenuContainer key="2" />
          ]}>
          <WalletDetailsLayout />
        </FixedLeftFluidLayout>
      )}
    />
    <Route path={'/exchange'} component={ExchangePage} />
    <Route path={'/buy-sell'} component={BuySellPage} />
    <Route path={'/wallets/product-details'} component={ProductDetailsPage} />
    <Route path={'/settings'} component={SettingsPage} />
    {/* <Route path={'/component'} component={ComponentExhibitPage} /> */}
    <Route
      path={'/margin-balances'}
      component={() => (
        <MarginBalancesLayout detailsLink="/wallets/wallet-detail" />
      )}
    />
    <Route
      path={'/asset-activity-details'}
      component={AssetActivityDetailsLayout}
    />

    {/* Investor portal Routes */}
    <Route exact path={'/digital-assets'} component={DigitalAssetsPage} />
    <Route
      path={'/digital-assets/:id'}
      component={DigitalAssetsOfferingProfilePage}
    />
    <Route exact path={'/credit-card'} component={MercuryoPage} />
    <Route path={'/credit-card/card-result'} component={CardResultPage} />
    <Route path={'/sto-presale-order'} component={PurchaseFormPage} />
    <Route path={'/AMA-order'} component={PurchaseFormPage_AMA} />
    <Route
      path={'/WNW-special-order'}
      component={PurchaseFormPage_WNW_special}
    />
    <Route
      path={'/GreenX-Preseed-Order'}
      component={PurchaseFormPage_GreenX_Preseed}
    />
    <Route
      path={'/GreenX-Seed-Round-Order'}
      component={PurchaseFormPage_GX_Presale}
    />
    {/* <Route path={'/2UT-CM-sale-channel'} component={CMSaleChannel} /> */}
    {/* <Route path={'/credit-card/success'} component={SuccessPage} />
    <Route path={'/credit-card/error'} component={ErrorPage} /> */}
    {/*
    <Route exact path={'/cash-out'} component={CashOutPage} />
    <Route path={'/cash-out/prepaid-cards'} component={PrepaidCardsPage} />
    <Route path={'/cash-out/gift-cards'} component={GiftCardsPage} />*/}
  </React.Fragment>
);

export default withAuthentication(InteriorPage);
